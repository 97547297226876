// github.com/olizilla/ipfs-content-addressed-archiver
// IPFS FILE API
// github.com/ipfs/js-ipfs/blob/master/docs/core-api/FILES.md#filecontent
// EXAMPLE
// https://github.com/ipfs-examples/js-ipfs-examples
// CAR API
// github.com/ipld/js-car#usage
import{CarReader}from'@ipld/car/reader';import{CarSplitter}from'carbites';import{IdbBlockStore}from'ipfs-car/blockstore/idb';import{NFTStorage}from'nft.storage';import{pack}from'ipfs-car/pack';import getFileTypeFromB64 from'./getFileTypeFromB64';// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
const b64toBlob=async b64Data=>{const blob=await(await fetch(b64Data)).blob();return blob;};const blobToCar=async blob=>{const blockstore=new IdbBlockStore();const{root,out}=await pack({// input: [new Uint8Array([21, 31, 41])],
input:blob,blockstore,wrapWithDirectory:false,// Wraps input into a directory. Defaults to `true`
maxChunkSize:262144// The maximum block size in bytes. Defaults to `262144`. Max safe value is < 1048576 (1MiB)
});blockstore.close();return{root,out};};const _blobArrToCar=async blobArr=>{const blockstore=new IdbBlockStore();const{root,out}=await pack({// input: [new Uint8Array([21, 31, 41])],
input:blobArr,blockstore,// issue: how should it be customized?
wrapWithDirectory:false,// Wraps input into a directory. Defaults to `true`
// issue: how should it be customized?
maxChunkSize:262144// The maximum block size in bytes. Defaults to `262144`. Max safe value is < 1048576 (1MiB)
});blockstore.close();return{root,out};};const splitCar=async out=>{const bigCar=await CarReader.fromIterable(out);const targetSize=1024*1024*90;// chunk to ~100MB CARs
const splitter=new CarSplitter(bigCar,targetSize);// (simple strategy)
return splitter;};const CarToNFTStorage=async out=>{const storage=new NFTStorage({token:process.env.REACT_APP_NFT_STORAGE_KEY});const carReader=await CarReader.fromIterable(out);const cid=await storage.storeCar(carReader);return cid;};const _blobToB64=blob=>{const reader=new FileReader();reader.readAsDataURL(blob);reader.onloadend=()=>{};};const _fileToB64=async file=>{const reader=new FileReader();reader.readAsDataURL(file);return new Promise(resolve=>{reader.onloadend=()=>{const base64data=reader.result;resolve(base64data);};});};// stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
export async function b64ToFile(b64,filename){const arr=b64.split(',');const mime=arr[0].match(/:(.*?);/)[1];const bstr=atob(arr[1]);let n=bstr.length;const u8arr=new Uint8Array(n);while(n!==0){n-=1;u8arr[n]=bstr.charCodeAt(n);}return new File([u8arr],filename,{type:mime});}export async function b64ToNFTStorage(b64){const blob=await b64toBlob(b64);const{out}=await blobToCar(blob);const splitter=await splitCar(out);const cidArr=[];// eslint-disable-next-line no-restricted-syntax
for await(const car of splitter.cars()){// Each `car` is an AsyncIterable<Uint8Array>
const cid=await CarToNFTStorage(car);cidArr.push(cid);}return cidArr;}// no file name is needed
// generated filearr e.g. [1.png, 2.png,...]
export async function b64ArrToFileArr(b64Arr){// complete for uploading to nft storage, but cannot track progress ...
const fileArr=[];for(let i=0;i<b64Arr.length;i+=1){const fileType=getFileTypeFromB64(b64Arr[i]);// eslint-disable-next-line no-await-in-loop
const file=await b64ToFile(b64Arr[i],`${i}.${fileType}`);fileArr.push(file);}return fileArr;}export async function fileToNFTStorage(file){const{cid,car}=await NFTStorage.encodeBlob(file);const storage=new NFTStorage({token:process.env.REACT_APP_NFT_STORAGE_KEY});const _cid=await storage.storeCar(car);if(cid.toString()!==_cid.toString())throw Error('cid not equal');return _cid;}export async function fileArrToNFTStorage(fileArr,countUploadedRef,setCountUploaded,setTotalCarFileSize){// eslint-disable-next-line no-param-reassign
countUploadedRef.current=0;const storage=new NFTStorage({token:process.env.REACT_APP_NFT_STORAGE_KEY});const{cid,car}=await NFTStorage.encodeDirectory(fileArr);let _size=0;// eslint-disable-next-line no-restricted-syntax
for await(const block of car.blocks()){_size+=block.bytes.BYTES_PER_ELEMENT*block.bytes.length;}setTotalCarFileSize(_size);const dirCID=await storage.storeCar(car,{onStoredChunk:size=>{// eslint-disable-next-line no-param-reassign
countUploadedRef.current+=size;setCountUploaded(countUploadedRef.current);}});if(cid.toString()!==dirCID.toString())throw Error('cid not equal');return dirCID;}