import{META_DATA_COMPILER}from'src/config/config';import getFileTypeFromB64 from'./getFileTypeFromB64';export function genMetaDataArr(dirName,desc,dirCID,randGenPics,layers,extraMetadata){const selectedPics=[];for(let i=0;i<randGenPics.ordering.length;i+=1){selectedPics.push(randGenPics.getItemAtIndex(i));}const metaDataArr=selectedPics.map((pic,index)=>{const fileType=getFileTypeFromB64(pic.getSrc());let metadata={name:`${dirName} #${index}`,description:desc,image:`ipfs://${dirCID}/${index}.${fileType}`,attributes:[...pic._picIdArr.map((picId,_index)=>{return layers.getLayerAtIndex(_index).isShowAttr?{trait_type:layers.getLayerAtIndex(_index).layerName,value:layers.getLayerAtIndex(_index).picDict[picId].picName}:{};}),...pic.extraAttr.map(attrPair=>{return{trait_type:attrPair[0],value:attrPair[1]};})]};if(extraMetadata)metadata={...metadata,...JSON.parse(extraMetadata)};metadata.attributes=metadata.attributes.filter(attr=>attr.value!=null||attr.trait_type!=null);return{...metadata,compiler:META_DATA_COMPILER};});return metaDataArr;}export const metaDataToFile=(metaData,filename)=>{const jsonString=JSON.stringify(metaData);return new File([jsonString],filename,{type:'application/json'});};export const metaDataArrToFileArr=metaDataArr=>{const fileArr=[];for(let i=0;i<metaDataArr.length;i+=1){fileArr.push(metaDataToFile(metaDataArr[i],`${i}.json`));}return fileArr;};export const genContractLevelMetaData=(collectionName,desc,externalLink,sellerFeeBasis,feeRecipient,collectionPicCID)=>{return{name:collectionName,description:desc,image:`ipfs://${collectionPicCID}`,external_link:externalLink,seller_fee_basis_points:sellerFeeBasis,fee_recipient:feeRecipient};};// export async function metaDataArrToStorage(
//   dirName: string,
//   desc: string,
//   dirCID: string,
//   randGenPics: MergePics,
//   layers: Layers,
//   setTotalUploadSize: (totalUploadSize: number) => void,
//   countUploadedRef: MutableRefObject<number>,
//   setCountUploaded: (countUpload: number) => void
// ) {
//   const metaDataArr = genMetaDataArr(
//     dirName,
//     desc,
//     dirCID,
//     randGenPics,
//     layers
//   );
//   const fileArr = [];
//   for (let i = 0; i < metaDataArr.length; i += 1) {
//     fileArr.push(metaDataToFile(metaDataArr[i], i));
//   }
//   const { cid, car } = await NFTStorage.encodeDirectory(fileArr);
//   let _size = 0;
//   // eslint-disable-next-line no-restricted-syntax
//   for await (const block of car.blocks()) {
//     _size += block.bytes.BYTES_PER_ELEMENT * block.bytes.length;
//   }
//   setTotalUploadSize(_size);
//   const NFT_STORAGE_KEY = '';
//   const storage = new NFTStorage({ token: NFT_STORAGE_KEY as string });
//   const metaDataDirCID = await storage.storeCar(car, {
//     onStoredChunk: (size: number) => {
//       // eslint-disable-next-line no-param-reassign
//       countUploadedRef.current += size;
//       setCountUploaded(countUploadedRef.current);
//     },
//   });
//   return metaDataDirCID;
// }